.main-productions {
	background-color: $dark;
	color: #fff;
	padding-top: 100px;
	padding-bottom: 100px;
	position: relative;
	overflow: hidden;
	
	@media (min-width: 768px) {
		padding-top: 200px;
		padding-bottom: 200px;
		overflow-x: hidden;
	}
	&__wrap {
		position: relative;
		display: flex;
		padding: 0 15px;
		@media (min-width: 768px) {
			padding: 0;
		}
	}
	&__pagination {
		bottom: 0;
		@media (min-width: 768px) {
			display: none;
		}
	}
	.swiper-button-next, .swiper-button-prev {
		display: none;
		outline: none;

		@media (min-width: 768px) {
			display: block;
		}
	}
	.swiper-pagination-bullet {
		outline: none;
	}
	.swiper-pagination-bullet-active {
		background-color: #fff;
	}
	&__aside {
		width: 270px;
		flex-shrink: 0;
	}
	&__content {
		width: 100%;
		
		@media (min-width: 768px) {
			width: 800px;
			margin: 40px 15px;
		}
	}
}
.productions-slider{
	width: 100%;
	overflow: visible;
	box-sizing: border-box;
	padding-bottom: 40px;
	
	@media (min-width: 768px) {
		width: 790px;
		margin: 0 auto 120px;
		padding-bottom: 0;
	}
}
.productions-slider-item {
	width: 100%;
	padding: 0;
	box-sizing: border-box;
	
	@media (min-width: 768px) {
		padding: 30px;
	}
}
.productions-slider-item__desc {
	position: relative;
	background-color: #fff;
	min-height: 240px;
	padding-bottom: 20px;
	box-sizing: content-box;
	
	@media (min-width: 768px) {
		padding: 50px 40px 50px 390px;
	}
}
.productions-slider-item__img {
	width: 100%;
	height: 240px;
	margin-bottom: 15px;
	object-fit: cover;
	
	@media (min-width: 768px) {
		position: absolute;
		display: block;
		width: 380px;
		height: 280px;
		margin-bottom: 0;
		top: -30px;
		left: -30px;
	}
}
.productions-slider-item__title {
	font-size: 18px;
	text-transform: uppercase;
	color: #000;
	margin-bottom: 15px;
	padding: 0 15px;
	
	@media (min-width: 768px) {
		padding: 0;
	}
}
.productions-slider-item__text {
	font-size: 15px;
	color: #000;
	margin-bottom: 20px;
	padding: 0 15px;
	
	@media (min-width: 768px) {
		margin-bottom: 35px;
	}
}
.productions-slider-item__btn {
	border: 1px solid #000;
	color: #000;
	padding: 5px 20px;
	width: 210px;
	margin: 0 auto;
	
	@media (min-width: 768px) {
		margin: 0;
	}
}
.productions-slider-item__btn:hover {
	border-color: transparent;
	color: #fff;
	span {
		position: relative;
		z-index: 2;
	}
	&::before {
		z-index: 1;
	}
}

.productions-slider .swiper-button-next {
	outline: none;
	right: -5px;
	
	@media (min-width: 768px) {
		right: -30px;
	}
}
.productions-slider .swiper-button-prev {
	outline: none;
	left: -5px;
	
	@media (min-width: 768px) {
		left: -50px;
	}
}
.productions-slider-count {
	display: none;
	
	@media (min-width: 768px) {
		display: block;
		position: absolute;
		top: 350px;
		left: 68px;
		z-index: 5;
		color: #222;
	}
}
.productions-slider-count__current {
	position: relative;
	font-weight: 600;
	color: #000;
	padding-left: 35px;
	&::before {
		position: absolute;
		top: 50%;
		left: 0;
		content: "";
		display: block;
		height: 1px;
		width: 30px;
		background: linear-gradient(to left, #fff, #ae0926);
	}
}