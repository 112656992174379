.main-products {
	background-color: #fff;
	padding-top: 100px;
	padding-bottom: 50px;
	overflow: hidden;
	
	@media (min-width: 768px) {
		padding-top: 0;
		padding-bottom: 300px;
//		overflow: visible;
	}
	&::before {
		@media (min-width: 768px) {
			content: '';
			display: block;
			height: 1px;
			width: 100%;
			position: absolute;
			bottom: calc(100% - 3px);
			left: 0;
			z-index: 11;
			background: linear-gradient(to top, rgba(#fff, 1) 50%, rgba(#fff, .7) 80%, rgba(#fff, .3) 90%, rgba(#fff, 0) 100%);
			transition: .8s linear;
		}
	}
	&.animated {
		&::before {
			@media (min-width: 768px) {
				opacity: 1;
				height: 130vh;
				transition: .8s linear;
			}
		}
	}
	&__wrapper {
		position: relative;
		margin-top: 100px;
		width: 100%;
		padding: 0 15px;
		
		@media (min-width: 768px) {
			width: auto;
		}
	}
	&__pagination {
		bottom: 0;
		@media (min-width: 768px) {
			display: none;
		}
	}
	.swiper-button-next, .swiper-button-prev {
		display: none;
		outline: none;

		@media (min-width: 768px) {
			display: block;
		}
	}
	.swiper-pagination-bullet {
		outline: none;
	}
	.swiper-pagination-bullet-active {
		background-color: #000;
	}
}

.main-products-slider {
	position: relative;
	overflow: visible;
	padding-bottom: 30px;
	&__nav {
		position: absolute;
		display: flex;
		margin-left: -35px;
    left: 50%;
    top: -65px;
		.arrows__left {
			outline: 0;
			margin-right: 5px;
			cursor: pointer;
			width: 30px;
			height: 30px;
			position: relative;
			&:hover {
				.arrows__border {
					border: 3px solid $accent;
					border-top: none;
					border-right: none;
					top: 0;
				}
			}
			.arrows__border {
				display: block;
				content: '';
				width: 20px;
				height: 20px;
				border: 2px solid $accent;
				border-top: none;
				border-right: none;
				transform: rotate(45deg);
				transition: all 0.1s;
				position: absolute;
				left: 4px;
				top: 1px;
			}
		}
		.vert-line {
			width: 1px;
			height: 24px;
			background: #999;
			display: block;
			content: '';
		}
		.arrows__right {
			outline: 0;
			margin-left: 5px;
			cursor: pointer;
			width: 30px;
			height: 30px;
			position: relative;
			&:hover {
				.arrows__border {
					border: 3px solid $accent;
					border-top: none;
					border-left: none;
					top: 0;
				}
			}
			.arrows__border {
				display: block;
				content: '';
				width: 20px;
				height: 20px;
				border: 2px solid $accent;
				border-top: none;
				border-left: none;
				transform: rotate(-45deg);
				transition: all 0.1s;
				position: absolute;
				right: 5px;
				top: 1px;
			}
		}
	}
	&__item {
		position: relative;
		display: block;
		width: 100%;
		margin-bottom: 30px;
		
		@media (min-width: 768px) {
			width: auto;
		}
		&:hover {
			.main-products-slider__img {
				transform: scale(1.1);
			}
			.main-products-slider__border {
				border-color: $accent;
			}
			.main-products-slider__text {
				color: $accent;
			}
		}
	}
	&__img {
		background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    transition: transform .7s;
    transform: scale(1);
    width: 100%;
    height: 100%;
	}
	&__img-wrap {
		position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: 290px;
    overflow: hidden;
		box-shadow: 0 10px 20px 0 rgba(159, 164, 189, .5);
		
		@media (min-width: 768px) {
			height: 370px;
		}
	}
	&__border {
		position: absolute;
    left: 15px;
    top: 15px;
    right: 15px;
    bottom: 8px;
    border: 1px solid #000;
    transition: all 0.3s linear;
	}
	&__text {
		position: relative;
		display: block;
		width: 240px;
		min-height: 52px;
		padding: 0 10px;
		left: 30px;
    top: 30px;
		font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    font-weight: 700;
    line-height: 26px;
    color: $dark;
    background: #fff;
    transition: color 0.3s linear;
		
		@media (min-width: 768px) {
			width: 420px;
		}
	}
}
