.main-cooperation {
	padding-top: 50px;
	background-color: #fff;
	
	@media (min-width: 768px) {
		padding-top: 0;
	}
	&::before {
		@media (min-width: 768px) {
			content: '';
			display: block;
			height: 1px;
			width: 100%;
			position: absolute;
			bottom: calc(100% - 3px);
			left: 0;
			z-index: 11;
			background: linear-gradient(to top, rgba(#fff, 1) 50%, rgba(#fff, .7) 80%, rgba(#fff, .3) 90%, rgba(#fff, 0) 100%);
			transition: .8s linear;
		}
	}
	&.animated {
		&::before {
			@media (min-width: 768px) {
				opacity: 1;
				height: 130vh;
				transition: .8s linear;
			}
		}
		.main-cooperation-item {
			transform: translateY(0);
		}
		.main-cooperation-item:nth-child(1) {
			transition: 0.5s linear;
		}
		.main-cooperation-item:nth-child(2) {
			transition: 1.0s linear;
		}
	}
	&__wrap {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		padding: 0 15px 50px;
		
		@media (min-width: 768px) {
			flex-direction: row;
			align-items: flex-start;
			justify-content: space-between;
			padding: 0 0 300px;
		}
	}
}
.main-cooperation-item {
	color: #fff;
	background-color: $dark;
	width: 100%;
	margin-bottom: 50px;
	padding: 20px;
	transform: translateY(0);
  transition: all 0.35s;
	
	@media (min-width: 768px) {
		width: 530px;
		margin-bottom: 0;
		padding: 30px;
		transform: translateY(300px);
	}
	&:hover {
		box-shadow: 3px 3px 40px 5px rgba(0,0,0,.5);
	}
	&__title {
		font-size: 18px;
		line-height: 20px;
		&::after {
			display: block;
			content: '';
			height: 5px;
			width: 50px;
			background: $gradient;
			margin-top: 12px;
		}
	}
	&__text {
		font-size: 16px;
    line-height: 21px;
    vertical-align: middle;
    padding-top: 25px;
    padding-bottom: 40px;
	}
	&__content {
		padding-bottom: 15px;
	}
	&__row {
		font-size: 14px;
    padding: 15px 0;
    display: flex;
		span {
			padding-left: 45px;
			position: relative;
			margin-right: 15px;
			&::before {
				content: '';
				display: block;
				height: 1px;
				width: 30px;
				position: absolute;
				top: 11px;
				left: 0;
				background: $gradient;
			}
		}
	}
	&__btn-wrap {
		.btn {
			box-shadow: 0px 1px 10px 0px rgba(255, 255, 255, 0.35);
		}
	}
}
