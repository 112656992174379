.productions {
	color: #fff;
}
.productions__wrapper {
	width: 100%;
	margin: 100px auto 60px;
	padding: 0 15px;
	
	@media (min-width: 768px) {
		width: 1000px;
		margin: 120px auto 60px;
		padding: 0;
	}
}
.productions__item {
	margin-bottom: 70px;
}
.productions__title {
	font-size: 22px;
	padding-bottom: 12px;
	line-height: 1.5;
	
	@media (min-width: 768px) {
		font-size: 40px;
		padding-bottom: 20px;
	}
}
.productions__title::before {
	content: '';
	display: block;
	margin-bottom: 15px;
	width: 70px;
	height: 2px;
	background: linear-gradient(to left, #222, #ae0926);
}
.productions__link {
	display: block;
	padding: 25px 45px 25px 25px;
	position: relative;
	border-top: 1px solid #fff;
	cursor: pointer;
	
	@media (min-width: 768px) {
		padding: 30px 45px;
	}
}
.productions__number {
	position: absolute;
	font-size: 12px;
	top: 10px;
	left: 0;
	color: #ae0926;
}
.productions__name {
	font-size: 18px;
	color: #fff;
	transition: color 0.25s linear;
	
	@media (min-width: 768px) {
		font-size: 22px;
	}
}
.productions-arrow {
	position: absolute;
	right: 5px;
	top: 50%;
	transform: translateY(calc(-50% - 5px));
	transition: all 0.2s;
}
.productions-arrow__inner {
	  display: block;
    width: 20px;
    height: 20px;
    border: 2px solid #fff;
    border-top: none;
    border-left: none;
    transform: rotate(45deg);
    transition: all 0.2s;
}
.productions__link:hover .productions__name {
	color: #ae0926;
}
.productions__link:hover .productions-arrow__inner {
	border-color: #ae0926;
}
.js-accordion-item.active .productions-arrow {
	transform: translateY(calc(-50% + 5px));
}
.js-accordion-item.active .productions-arrow__inner {
	transform: rotate(-135deg);
}
.productions-content {
	padding: 15px 10px 50px;
	
	@media (min-width: 768px) {
		padding: 15px 20px 50px;
	}
}
.productions-content__wrapper {}
.productions-content__text {
	margin-bottom: 20px;
}
.productions-content__img {
	display: block;
	width: 100%;
	height: auto;
}