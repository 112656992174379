.next-section {
	display: none;
	@media (min-width: 768px) {
		display: block;
		position: absolute;
		bottom: 0;
		padding-bottom: 150px;
		width: 150px;
		left: 50%;
		margin-left: -75px;
		text-align: center;
		color: #fff;
		font-size: 12px;
		text-transform: uppercase;
		font-weight: 700;
		letter-spacing: .1em;
		cursor: pointer;
		z-index: 2;
		-webkit-transform: translateZ(0);
		transform: translateZ(0);
		transition: background-color .35s;
	}
}
.next-section:before {
	@media (min-width: 768px) {
		width: 17px;
    height: 140px;
    position: absolute;
    background-color: rgba(228,0,43,0);
		box-shadow: 0px 0px 3px 0 rgb(255, 0, 0);
    left: calc(50% - 10px);
    top: 30px;
		bottom: 0;
    -webkit-transition: -webkit-box-shadow .35s;
    -o-transition: box-shadow .35s;
    transition: box-shadow .35s;
    transition: box-shadow .35s, -webkit-box-shadow .35s;
    content: '';
		transition: all .35s;
	}
}
.next-section:hover:before {
	@media (min-width: 768px) {
    background-color: rgba(228,0,43,1);
	}
}

.main-cooperation .next-section__text,
.main-products .next-section__text {
	color: #222;
}
.cc-window {
	display: none;
	
	@media (min-width: 768px) {
		display: block;
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		background: rgba(0,0,0,0.8);
		z-index: 900;
		transition: 0.3s linear;
	}
}
.cc-window.disable {
	display: none;
}
.cc-window__wrapper {
	display: flex;
	
}
.cc-window__text {
	padding: 10px 15px;
	color: #fff;
}
.cc-window__close_btn {
	content: '';
	display: block;
	width: 40px;
	height: 40px;
	cursor: pointer;
}
.cc-window__close {
	position: relative;
}
.cc-window__close_btn:before {
	content: '';
	display: block;
	width: 20px;
	height: 2px;
	background: #fff;
	transform: rotate(-45deg);
	position: absolute;
	top: 20px;
	right: 10px;
}
.cc-window__close_btn:after {
	content: '';
	display: block;
	width: 20px;
	height: 2px;
	background: #fff;
	transform: rotate(45deg);
	position: absolute;
	top: 20px;
	right: 10px;
}

.preloader {
	background: #222;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100000;
}
.preloader.done {
	opacity: 0;
	visibility: hidden;
}
.page-preloader__wrapper {
	position: relative;
	top: 50%;
	left: 50%;
	width: 120px;
/*	height: 134px;*/
	transform: translate(-50%, -50%);
	
	@media (min-width: 768px) {
		width: 240px;
	}
}
.page-preloader__wrapper svg {
    stroke-miterlimit: 10;
    stroke-width: 2px;
}
svg .red-path{
	fill: rgb(229, 40, 34);
	fill-opacity: 0;
	stroke: rgb(229, 40, 34);
	transition: all 0.2s;
}
svg .white-path{
	fill: rgb(255, 255, 255);
	fill-opacity: 0;
	stroke: rgb(255, 255, 255);
	transition: all 0.2s;
}