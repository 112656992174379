.product-container {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10001;
	overflow: hidden;
	position: fixed;
	background: rgba(#0b0b0b, 0.8);
}

.product-container__bg {
	height: 100%;
	width: 100%;
	overflow-y: auto;
}

.product__popup .headline__title::after {
	content: '';
	display: block;
	width: 70px;
	height: 5px;
	background-color: #af0827;
	margin: 15px auto 0;
}
.product-popup__nav .nav-previous,
.product-popup__nav .nav-next {
		background-color: #e6e7e8;
		max-height: 0;
		padding: 0;
		opacity: 0;
		overflow: hidden;
		width: auto;
		cursor: pointer;
		position: absolute;
		top: 50vh;
		-webkit-transform: translateY(-50%);
		-moz-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		-o-transform: translateY(-50%);
		transform: translateY(-50%);
		transition: all 0.3s linear;
		z-index: 1000;
	}
.product-popup__nav .nav-previous > span {
		left: 0;
	}
.product-popup__nav .nav-next > span {
		right: -12px;
	}
.product-popup__nav .nav-previous:hover,
.product-popup__nav > span:hover + .nav-previous,
.product-popup__nav .nav-next:hover,
.product-popup__nav > span:hover + .nav-next {
		max-height: 600px;
		opacity: 1;
	}

.product-popup__nav > span,
.product-popup__nav > div > span {
		display: block;
		letter-spacing: 0;
		text-transform: uppercase;
		cursor: pointer;
		position: absolute;
		top: 50vh;
		-webkit-transform: rotate(-90deg) translateY(-50%);
		-moz-transform: rotate(-90deg) translateY(-50%);
		-ms-transform: rotate(-90deg) translateY(-50%);
		-o-transform: rotate(-90deg) translateY(-50%);
		transform: rotate(-90deg) translateY(-50%);
	}
.product-popup__nav > div > span {
	color: #fff;
	top: 50%;
	z-index: 1001;
}
.product-popup__nav .prev-trig {
		left: 0;
	}

.product-popup__nav .next-trig {
		right: -12px;
	}

.product-popup__nav .nav-previous {
		left: 0;
	}

.product-popup__nav .nav-next {
		right: 0;
	}

.product-popup__nav .nav-previous a {
		box-sizing: border-box;
		padding: 40px 50px 40px 70px;
	}
.product-popup__nav .entry-meta {
	font-size: 12px;
	font-weight: 400;
	letter-spacing: 1.2px;
	margin-bottom: 10px;
	display: block;
}
.product-popup__nav .post-title {
	font-size: 18px;
	line-height: 27px;
	font-weight: 700;
	display: block;
}

.product-popup__nav .nav-next a {
	box-sizing: border-box;
		padding: 40px 70px 40px 50px;
	}

.product-popup__nav a {
		display: table-cell;
		height: 230px;
		vertical-align: middle;
		width: 460px;
		color: #fff;
	background-color: #222;
	}

.product-popup__nav .nav-previous a,
.product-popup__nav .nav-previous a span {
		text-align: right;
	}

.product-popup__nav .nav-next a,
.product-popup__nav .nav-next a span {
		text-align: left;
	}

.product__popup .footer__container {
	background-color: #222;
	padding-bottom: 60px;
	width: auto;
}
.product__popup {
	width: 100%;
	padding-top: 20px;
	margin: 0 auto;
	position: relative;
	z-index: 10003;
	background: #fff;
	
	@media (min-width: 768px) {
		width: 1180px;
		padding-top: 0;
	}
	
	&::before {
		
	}
}
.product__popup .mfp-close {
	overflow: visible;
	cursor: pointer;
	background: transparent;
	border: 0;
	-webkit-appearance: none;
	display: block;
	outline: none;
	padding: 0;
	z-index: 1046;
	box-shadow: none;
	touch-action: manipulation;
	position: fixed;
	background: #222;
	color: #fff;
	top: 10px;
	right: 10px;
	width: 40px;
	height: 40px;
	
	@media (min-width: 768px) {
		top: 20px;
		right: 50px;
		width: 44px;
		height: 44px;
		background: #222;
		color: #fff;
		border: 1px solid #ffffff;
	}
}

.product-popup__wrapper {
	height: 100%;
	background: #fff;
	margin: 0;
	
	@media (min-width: 768px) {
		margin: 0 150px;
	}
}
.product-popup__cont {

}
.product-popup__left {
	box-sizing: border-box;
	height: 250px;
	width: 100%;
	
	@media (min-width: 768px) {
		height: 450px;
	}
}
.product-popup__title {
	padding: 40px;
	font-size: 24px;
	line-height: 36px;
	font-weight: 100;
	color: #222;
	text-align: center;
}
.product-popup__buy {
	padding: 40px;
}
.product-popup__add-basket {
	
}
.product-popup__right {
	color: #222;
	padding: 30px 15px;
	font-size: 15px;
	box-sizing: border-box;
	
	@media (min-width: 768px) {
		padding: 40px 15px 40px 40px;
	}
}
.product-popup__right_content {

}
.product-popup__description {
	margin-bottom: 20px;
	line-height: 22px;
}
.product-popup__subtitle {
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 5px;
	line-height: 30px;
}
.product-popup__subtitle p {
	font-size: 16px;
	
}
.product-popup__info {
	margin-bottom: 20px;
	
	@media (min-width: 768px) {
		display: flex;
	}
}
.product-popup__promo {
}
.product-popup__promo .promo__content {
	color: #fff;
	padding: 40px 15px;
	background: linear-gradient(to right,rgba(34,34,34,1) 0%, rgba(34,34,34,0.99) 40%, transparent 100%);
	
	@media (min-width: 768px) {
		padding: 40px;
	}
}
.product-popup__promo .promo__title {
	margin-bottom: 10px;
	font-size: 20px;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: 1px;
	line-height: 28px;
	
	@media (min-width: 768px) {
		font-size: 24px;
		line-height: 36px;
	}
}
.product-popup__promo .promo__text {
	font-size: 15px;
	line-height: 22.5px;
	margin-bottom: 40px;
	
	@media (min-width: 768px) {
		width: 400px;
	}
}
.product-popup__promo .promo__btn {
	
}
.product-popup__promo.promo__text-right .promo__content {
	background: linear-gradient(to left,rgba(34,34,34,1) 0%, rgba(34,34,34,0.99) 40%, transparent 100%);
	text-align: right;
}

.product-popup__promo.promo__text-right .promo__text {
	margin-left: auto;
}
.product-popup__promo.promo__text-right .btn-block {
	margin-left: auto;
}

.product-popup__nav {
	position: relative;
	display: none;
	
	@media (min-width: 768px) {
		display: block;
	}
/*
	display: flex;
	justify-content: space-between;
	padding: 30px 15px;
	background: #222;
*/
}
.product-popup__nav span {
	
}
.product-popup__nav_link {
	width: 300px;
	font-size: 16px;
	font-weight: 700;
	padding: 20px;
	color: #fff;
	text-align: center;
	position: relative;
	cursor: pointer;
	transition: color 0.35s;
}
.product-popup__nav_link:hover {
	color: #af0827;
}
.product-popup__nav_prev {
	
}
.product-popup__nav_prev .arrows__border {
    display: block;
    content: '';
    width: 20px;
    height: 20px;
    border: 2px solid #af0827;
    border-top: none;
    border-right: none;
    transform: rotate(45deg) translateY(calc(-50% - 5px));
    transition: all 0.35s;
    position: absolute;
    left: 4px;
    top: 50%;
}
.product-popup__nav_prev:hover .arrows__border {
    border-color: #af0827;
}
.product-popup__nav_next {
	
}
.product-popup__nav_next .arrows__border {
    display: block;
    content: '';
    width: 20px;
    height: 20px;
    border: 2px solid #af0827;
    border-top: none;
    border-left: none;
    transform: rotate(-45deg) translateY(calc(-50% - 5px));
    transition: all 0.35s;
    position: absolute;
    right: 5px;
    top: 50%;
}
.product-popup__nav_next:hover .arrows__border {
    border-color: #af0827;
}
.info__item {
	margin-bottom: 10px;
	line-height: 22.5px;
	
	@media (min-width: 768px) {
		width: 30%;
		margin-bottom: 0;
	}
}
.info__item span {
	font-size: 20px;
	font-weight: 600;
	line-height: 30px;
}
.mfp-bg {
	z-index: 10001;
}
.mfp-wrap {
	z-index: 10002;
}
.product__popup .headline {
	display: block;
	text-align: center;
	margin: 0 auto 50px;
	color: #ffffff;
}

.product__popup .headline__subtitle {
	margin: 0 auto;
}
.ui-selectmenu-menu {
	z-index: 100000;
	
	@media (max-width: 768px - 1px) {
		left: 10px;
		width: 280px;
	}
}
.ui-button {
	box-sizing: border-box;
	padding: 20px 0;
	font-weight: 400;
	font-size: 16px;
	letter-spacing: normal;
	font-family: 'RalewayRegular', Arial, Helvetica, sans-serif;
	color: #fff;
	background-color: #222;
	border-radius: 0;
}
.ui-button:hover {
	color: #ffffff;
	background-color: #222;
}
.ui-selectmenu-button.ui-button {
	width: 100%;
	border: none;
	margin-bottom: 30px;
	
	@media (min-width: 768px) {
		width: 100%;
	}
}
.ui-state-active .ui-icon, .ui-button:active .ui-icon {
	background: none;
	content: '+';
}
.ui-selectmenu-icon.ui-icon.ui-icon-triangle-1-s {
	background: none;
	border: 8px solid #fff;
	border-bottom-width: 0;
	border-left-color: transparent;
	border-right-color: transparent;
	box-sizing: border-box;
	margin-top: 6px;
}
.ui-widget.ui-widget-content {
	border-color: #ae0926;
	border-top-width: 0;
}
.ui-widget-content {
	background: #222;
	color: #fff;
}

.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, a.ui-button:active, .ui-button:active, .ui-button.ui-state-active:hover {
	border: none;
	background: #ae0926;
	font-weight: normal;
	color: #fff;
}
.ui-menu .ui-state-focus, .ui-menu .ui-state-active {
	margin: 0;
}
.ui-widget {
	font-family: inherit;
	font-size: 16px;
}
.ui-state-hover, .ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-hover, .ui-state-focus, .ui-widget-content .ui-state-focus, .ui-widget-header .ui-state-focus, .ui-button:hover, .ui-button:focus {
	background: #222;
	color: #fff;
}
.ui-menu .ui-menu-item-wrapper {
	padding: 5px;
}
.promo__content {
	.btn {
		width: 210px;
		border: 1px solid #fff;
		transition: 0.2s linear;
		box-sizing: border-box;
		&:hover {
			border-color: transparent;
		}
	}
	.btn::before {
		z-index: 1;
	}
	span {
		z-index: 2;
	}
}
.promo__text-right {

	.btn {
		margin-left: auto;
	}
}
.product__popup .footer__container {
	padding-top: 60px;
}

.order-popup {
	width: auto;
	margin: 0 auto;
	position: relative;
	z-index: 1000;
	background: #fff;
	padding: 50px 0;
	background-color: #222;
	
	@media (min-width: 768px) {
		max-width: 1180px;
	}
	.headline__subtitle {
		padding: 0 10px;
		
		@media (min-width: 768px) {
			padding: 0;
		}
	}
	.headline {
		color: #fff;
	}
}
.mfp-close-btn-in .order-popup button.mfp-close {
	color: #fff;
}
.main-page .mfp-container {
	padding: 0;
}