.post-main {
	height: 100vh;
	position: relative;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	
	@media (min-width: 768px) {
		
	}
}
.post-main::after {
	content: '';
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-color: rgba(0,0,0,.3);
}
.post-main__wrapper {
	position: relative;
	display: flex;
	height: 100%;
	width: 300px;
	margin: 0 auto;
	z-index: 2;
	
	@media (min-width: 768px) {
		width: 1000px;
	}
}
.post-main__content {
	margin: auto 0;
	color: #fff;
}
.post-main__title {
	font-size: 24px;
	font-weight: 400;
	margin-bottom: 20px;
	line-height: 32px;
	
	@media (min-width: 768px) {
		font-size: 40px;
		line-height: 60px;
	}
}
.post-main__subtitle {
	font-size: 16px;
	width: 100%;
	margin-bottom: 30px;
	line-height: 24px;
	
	@media (min-width: 768px) {
		width: 500px;
	}
}
.post-main__date {
	font-size: 12px;
	padding: 0 30px;
	line-height: 40px;
	background: #af0827;
	display: inline-block;
}
.post-content {
	background-color: #fff;
	padding-top: 60px;
	padding-bottom: 120px;
}
.post-content__wrapper {
	width: 300px;
	margin: 0 auto;
	
	@media (min-width: 768px) {
		width: 1100px;
	}
}
.post-content__wrapper img {
	max-width: 100%;
	height: auto;
	display: block;
	margin: 40px 0;
	box-shadow: 0 0 15px 0 rgba(0,0,0,.5);
}
.post-content__first {
	margin: 0;
	padding: 0;
	border: 0;
	font: inherit;
	vertical-align: text-top;
	margin-bottom: 30px;
	font-size: 16px;
	line-height: 24px;
}
.post-content__first::first-letter {
	font-size: 60px;
	line-height: 90px;
	float: left;
	margin-right: 10px;
	margin-top: -15px;
}
.post-content__cols {
	display: flex;
	flex-direction: column;
	
	@media (min-width: 768px) {
		flex-direction: row;
	}
}
.post-content__left {
}
.post-content p {
	margin-bottom: 30px;
	font-size: 16px;
	line-height: 24px;
}
.post-content__right {
	position: relative;
	width: 100%;
	flex-shrink: 0;
	font-size: 24px;
	font-weight: 700;
	
	@media (min-width: 768px) {
		width: 500px;
	}
}
.post-content__right::before {
	content: '';
	display: block;
	width: 60px;
	height: 1px;
	background: #af0827;
	position: absolute;
	top: 0;
	left: 0;
	
	@media (min-width: 768px) {
		top: 17px;
		left: 60px;
	}
}
.post-content__cols blockquote {
	width: 100%;
	margin: 0;
	line-height: 36px;
	padding-top: 20px;
	margin-bottom: 40px;
	
	@media (min-width: 768px) {
		width: 350px;
		margin: 0 0 0 150px;
		padding-top: 0;
	}
}
.post-content__blockquote {
}
.post-content__title {
	font-size: 28px;
	line-height: 42px;
	font-weight: 700;
	margin-bottom: 10px;
}
.post-content ul {
	list-style-type: square;
	padding-left: 30px;
	margin-bottom: 30px;
}
.post-content li {
	list-style-type: square;
	font-size: 16px;
	line-height: 24px;
}
.post-main__nav {
	display: none;
	
	@media (min-width: 768px) {
		display: block;
	}
}
.nav-previous,
.nav-next {
		background-color: #e6e7e8;
		max-height: 0;
		padding: 0;
		opacity: 0;
		overflow: hidden;
		width: auto;
		cursor: pointer;
		position: fixed;
		top: 50%;
		-webkit-transform: translateY(-50%);
		-moz-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		-o-transform: translateY(-50%);
		transform: translateY(-50%);
		transition: all 0.3s linear;
		z-index: 1000;
	}
.nav-previous > span {
		left: -35px;
	}
.nav-next > span {
		right: -28px;
	}
.nav-previous:hover,
.post-main__nav > span:hover + .nav-previous,
.nav-next:hover,
.post-main__nav > span:hover + .nav-next {
		max-height: 600px;
		opacity: 1;
	}

.post-main__nav > span,
.post-main__nav > div > span {
		display: block;
		letter-spacing: 0;
		text-transform: uppercase;
		cursor: pointer;
		position: fixed;
		top: 50%;
		z-index: 10;
		-webkit-transform: rotate(-90deg) translateX(0.6em);
		-moz-transform: rotate(-90deg) translateX(0.6em);
		-ms-transform: rotate(-90deg) translateX(0.6em);
		-o-transform: rotate(-90deg) translateX(0.6em);
		transform: rotate(-90deg) translateX(0.6em);
	}

.post-main__nav .prev-trig {
		left: -35px;
	}

.post-main__nav .next-trig {
		right: -28px;
	}

.post-main__nav .nav-previous {
		left: 0;
	}

.post-main__nav .nav-next {
		right: 0;
	}

.post-main__nav .nav-previous a {
		box-sizing: border-box;
		padding: 40px 60px 40px 105px;
	}
.post-main__nav .entry-meta {
	font-size: 12px;
	line-height: 18px;
	font-weight: 400;
	letter-spacing: 1.2px;
	margin-bottom: 10px;
	display: block;
}
.post-main__nav .post-title {
	font-size: 18px;
	line-height: 27px;
	font-weight: 700;
	display: block;
}

.post-main__nav .nav-next a {
	box-sizing: border-box;
		padding: 40px 105px 40px 60px;
	}

.post-main__nav a {
		display: table-cell;
		height: 230px;
		vertical-align: middle;
		width: 500px;
		color: #222;
	}

.post-main__nav .nav-previous a,
.post-main__nav .nav-previous a span {
		text-align: right;
	}

.post-main__nav .nav-next a,
.post-main__nav .nav-next a span {
		text-align: left;
	}