.short-news__date {
	position: relative;
	overflow: hidden;
}
.short-news__date-bg {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	box-sizing: border-box;
	background: #222;
}
.short-news__date-bg-line {
	position: absolute;
	left: 0;
	bottom: 0;
	top: 0;
	right: 0;
	background: #af0827;
	transition: transform .42s cubic-bezier(.645, .045, .355, 1);
	transform: translate3d(-100%, 0, 0);
}
.short-news__date_text {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	color: #fff;
	text-decoration: none;
	box-sizing: border-box;
	transition: border-color 0.35s;
	transition: color 0.35s;
	background: transparent;
	border: none;
	z-index: 20;
	position: relative;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
a.short-news:hover .short-news__date-bg-line {
	transform: translate3d(0, 0, 0);
}
a.short-news:hover .short-news__date_text {
	color: #fff;
}
.short-news {
    position: relative;
    padding: 0 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-bottom: 32px;
    display: block;
    color: #fff;
}
.short-news:last-child {
    margin-bottom: 0
}

a.short-news:hover .short-news__title {
    color: #af0827;
    -webkit-transition-duration: .175s;
    -o-transition-duration: .175s;
    transition-duration: .175s
}
a.short-news:hover .short-news__box:first-child {
	
}
a.short-news:hover .short-news__box-img-wrap {
	
/*	padding: 6px;*/
/*
	border: 6px solid transparent;
	box-sizing: border-box;
*/

}

a.short-news:hover .short-news__box-img-wrap {
	top: 6px;
	bottom: 6px;
	left: 6px;
	right: 6px;
/*	box-sizing: border-box;*/

}
a.short-news:hover .short-news__box-img {
	width: 120%;
	height: 120%;
	margin: -10%;
	transition: all 0.35s;
}
a.short-news:hover .short-news__box-img-over {
	opacity: 1;
	transition: all 0.35s;
}
.short-news__line {
/*
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 100%;
    background: #af0827;
    -webkit-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
*/
		display: none;
}
.short-news_primary .short-news__title {
		font-family: RalewaySemiBold, sans-serif;
    font-size: 12px;
    line-height: 18px;
		text-transform: uppercase;
		font-weight: 400;
    margin-bottom: 16px;
}
.short-news__title:last-child, .short-news_primary .short-news__title:last-child {
    margin-bottom: 0
}
.short-news__content {
    display: block;
/*    overflow: hidden*/
}
.short-news__box {
    display: block;
}
.short-news__box:first-child {
	width: 100%;
	height: 300px;
	margin-bottom: 30px;
	background: linear-gradient(to left, #222, #ae0926);
}
.short-news__box-img-wrap {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: block;
	overflow: hidden;
/*	border: 0 solid #666;*/
	box-sizing: border-box;
	transition: all 0.35s;
}
.short-news__box-img {
	display: block;
	content: '';
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	transition: all 0.35s;
}
.short-news__box-img-over {
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(0,0,0,.4);
	position: absolute;
	opacity: 0;
	transition: all 0.35s;
}
.short-news__box-img-over-text {
	display: flex;
	align-items: center;
	transform: rotate(-90deg);
	font-family: RalewayRegular, sans-serif;
	font-size: 12px;
	line-height: 18px;
	letter-spacing: 1px;
	text-transform: uppercase;
	font-weight: 400;
}
.short-news__box-img-over-text::before,
.short-news__box-img-over-text::after {
	content: '';
	display: block;
	width: 40px;
	height: 1px;
	background: #fff;
}
.short-news__box-img-over-text::before {
	margin-right: 10px;
}
.short-news__box-img-over-text::after {
	margin-left: 10px;
}

.short-news__date {
    display: block;
		position: absolute;
		bottom: -18px;
		left: -15px;
    color: #fff;
    opacity: 1;
/*		padding: 10px 15px;*/
		padding: 0;
    font-size: 14px;
		width: 210px;
		line-height: 44px;
		text-align: center;
}
.short-news__title {
    display: block;
    font-size: 20px;
    line-height: 1.2;
    font-weight: 700;
    margin-bottom: 14px;
    -webkit-transition: color .35s;
    -o-transition: color .35s;
    transition: color .35s
}

.short-news__text {
    display: block;
		font-size: 14px;
	margin-bottom: 15px;
}
.short-news__text:last-child {
}
.short-news-box {
    color: #fff;
    position: relative;
    z-index: 10;
}

.short-news-box__aside {
    overflow: hidden;
    padding-right: 15px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    float: left
}
.short-news-box__title {
    font-size: 30px;
    line-height: 1;
    font-weight: 700;
    text-transform: uppercase
}
.short-news-box__content {
		position: relative;
		margin: 40px auto 0;
}
.short-news-box__list {
    margin-bottom: 56px;
		display: flex;
		justify-content: space-between;
}

.short-news-box__col {
    float: left;
    width: 330px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.blog__wrapper {
	width: 300px;
	margin: 120px auto 0;
	
	@media (min-width: 768px) {
		width: 900px;
	}
}
.blog__wrapper .short-news-box__list {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}
.blog__wrapper .short-news-box__col {
	width: 100%;
	float: none;
	margin-bottom: 100px;
	padding-right: 0;
	
	@media (min-width: 768px) {
		width: 420px;
	}
}
.blog__wrapper .short-news__box {
	position: relative;
}
