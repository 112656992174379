//footer { flex: 0 0 auto; }
.main-footer {
	background: $dark;
	border-top: 1px solid $gray;
	&__wrap {
		display: flex;
		flex-direction: column;
		width: 100%;
		padding: 50px 0;
		align-items: center;
		
		@media (min-width: 768px) {
			flex-direction: row;
			width: 1100px;
		}
	}
	&__name {
		color: #fff;
		transition: all .3s linear;
		&:hover,
		&:focus {
			color: $accent;
		}
	}
	&__list {
		display: flex;
		flex-direction: column;
		margin: 20px 0;
		padding: 0;
		align-items: center;
		list-style: none;
		
		@media (min-width: 768px) {
			flex-direction: row;
			margin: 0 0 0 auto;
		}
	}
	&__item {
		&:not(:last-child) {
			margin-bottom: 15px;
			
			@media (min-width: 768px) {
				margin-bottom: 0;
				margin-right: 40px;
			}
		}
	}
	&__link {
		display: block;
		position: relative;
		color: #fff;
		font-size: 12px;
		line-height: 18px;
		letter-spacing: 1.2px;
		text-transform: uppercase;
		font-family: "RalewaySemiBold", Arial, sans-serif;
		transition: all .3s linear;
		&:hover,
		&:focus {
			color: $accent;
		}
	}
}

.main-footer-social {
	display: flex;
	
	@media (min-width: 768px) {
		margin-left: 40px;
	}
	&__link {
		display: block;
    width: 20px;
    height: 20px;
		&:not(:last-child) {
			margin-right: 30px;
			@media (min-width: 768px) {
				margin-right: 15px;
			}
		}
		svg {
		
		}
		path {
			fill: #fff;
			transition: all .3s linear;
		}
		&:hover,
		&:focus {
			path {
				fill: $accent;
			}
		}
	}
}

.copyright {
	padding: 15px;
	text-align: center;
	color: #fff;
	border-top: 1px solid $gray;
}

.form-search__field::-webkit-input-placeholder {
    color: rgba(209, 213, 222, .56);
    opacity: 1
}
.form-search__field::-moz-placeholder {
    color: rgba(209, 213, 222, .56);
    opacity: 1
}
.form-search__field:-ms-input-placeholder {
    color: rgba(209, 213, 222, .56);
    opacity: 1;
    -webkit-transition: opacity .35s;
    -o-transition: opacity .35s;
    transition: opacity .35s
}
.form-search__field::placeholder {
    color: rgba(209, 213, 222, .56);
    opacity: 1
}
.form-search__field::-webkit-input-placeholder {
    -webkit-transition: opacity .35s;
    -o-transition: opacity .35s;
    transition: opacity .35s
}
.form-search__field::-moz-placeholder {
    -webkit-transition: opacity .35s;
    -o-transition: opacity .35s;
    transition: opacity .35s
}
.form-search__field::placeholder {
    -webkit-transition: opacity .35s;
    -o-transition: opacity .35s;
    transition: opacity .35s
}
.ui-selectmenu-button.ui-button,
.form-search__wrapper {
    position: relative;
/*    margin-bottom: 10px*/
}
.ui-selectmenu-button.ui-button:before,
.order-popup .form-search__wrapper:before,
.product__popup .form-search__wrapper:before {
	height: 1px;
	background: #af0827;
}

.form-search__wrapper:before {
    height: 1px;
		background: #ffffff;
}

.ui-selectmenu-button.ui-button:after, .ui-selectmenu-button.ui-button:before,
.form-search__wrapper:after, .form-search__wrapper:before {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    content: ''
}
.menu-bottom__row .form-search__wrapper:before {
    background: #ffffff;
}
.ui-selectmenu-button.ui-button {
	outline: none;
}
.ui-selectmenu-button.ui-button:after,
.form-search__wrapper:after {
    height: 2px;
    -webkit-box-shadow: 0 1px 3px 0 rgba(228, 0, 43, .35), 0 3px 15px 0 rgba(228, 0, 43, .35);
    box-shadow: 0 1px 3px 0 rgba(228, 0, 43, .35), 0 3px 15px 0 rgba(228, 0, 43, .35);
    background: #af0827;
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: -webkit-transform .35s;
    -o-transition: transform .35s;
    transition: transform .35s;
    transition: transform .35s, -webkit-transform .35s
}
.ui-selectmenu-button.ui-button:hover::after,
.form-search__wrapper:hover::after {
	  -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1)
}

.footer-form {
	padding: 100px 0;
	background: #222;
	color: #ffffff;
	&__container {
		width: 100%;
		margin: 0;
		
		@media (min-width: 768px) {
			width: 1200px;
			margin: 0 auto;
		}
	}
}

.headline {
	display: block;
	text-align: center;
	margin: 0 auto 50px;
}
.headline__title {
	font-size: 20px;
	line-height: 22px;
	letter-spacing: 1px;
	text-transform: uppercase;
	font-weight: 400;
	font-family: "RalewayRegular", sans-serif;
	margin-bottom: 15px;
	position: relative;
	display: inline-block;
}
.headline__subtitle {
	font-size: 16px;
	line-height: 1.3333;
	max-width: 540px;
	margin: 0 auto;
	position: relative;
}
.contact-us .inputs__line {
	margin-bottom: 30px;
	
	@media (min-width: 768px) {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
	}
}
.contact-us .form-search__wrapper {
	width: 100%;
	
	@media (min-width: 768px) {
		width: 100%;
	}
}

.contact-us .inputs__line .form-search__wrapper {
	width: 100%;
	
	@media (min-width: 768px) {
		width: 260px;
	}
}

.contact-us input[type="checkbox"] {
	-webkit-appearance: checkbox;
}
.contact-us .form-search__field {
	padding-right: 0;
	font-size: 25px;
	background: transparent;
	color: #222;
	font-family: 'RalewayRegular', Arial, Helvetica, sans-serif;
}
.contact-us .form-search__field::placeholder {
	color: #fff;
}
.contact-us .form-search__field {
	font-weight: 400;
	font-size: 16px;
	letter-spacing: normal;
	font-family: 'RalewayRegular', Arial, Helvetica, sans-serif;
	color: #ffffff;
}
.contact-us .form-search__field::placeholder {
	color: #fff;
}
.contact-us {
	display: block;
	margin: 0 auto;
	width: 300px;
	position: relative;
	
	padding: 30px 20px 100px;
	background: #222;
	box-shadow: 0 2px 20px rgba(255,255,255,0.4);
	
	@media (min-width: 768px) {
		box-sizing: content-box;
		width: 660px;
		padding: 40px 130px 60px 60px;
	}
}
.submit {
    width: 100%;
		height: 70px;
    display: block;
    position: absolute;
    right: 0;
		left: 0;
    bottom: 0;
    border: none;
    cursor: pointer;
    overflow: hidden;
    background: linear-gradient(to top, #222, #ae0926);
	
	@media (min-width: 768px) {
		height: auto;
		width: 70px;
		top: 0;
		left: auto;
	}
		
}
.submit:before {
    content: '';
    width: 100%;
    height: 200%;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s linear;
}
.submit input {
    visibility: hidden;
    opacity: 0;
}
.submit svg {
    width: 30px;
    height: 17px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -5px;
    margin-left: -15px;
    z-index: 1;
    transition: all 0.5s ease;
}
.submit svg polygon {
    fill: #FFF;
}
.submit:hover:before {
    opacity: 1;
}
.submit:hover svg {
    animation: submit-button 1s ease both;
}
@keyframes submit-button {
	0% {
			transform: translate3d(0, 0, 0);
			opacity: 1;
	}

	50% {
			transform: translate3d(40px, -16px, 0);
			opacity: 0;
	}
	51% {
			transform: translate3d(-40px, 16px, 0);
			opacity: 0;
	}
	100% {
			transform: translate3d(0, 0, 0);
			opacity: 1;
	}
}
.form-search__field {
	display: inline-block;
	vertical-align: top;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	width: 100%;
	font: 14px/1.5 Arial, Helvetica, sans-serif;
	font-weight: 700;
	font-size: 56px;
	line-height: 1.2;
	padding: 20px 70px 20px 0;
	border: 0;
	color: #2d2d34;
	outline: 0;
}