.about {
	color: #ffffff;
	font-size: 16px;
}
.about__wrapper {
	width: 300px;
	margin: 0 auto;
	
	@media (min-width: 768px) {
		width: 1100px;
	}
}
.about__title {
	font-weight: normal;
	padding: 200px 0 120px;
/*	text-align: center;*/
	font-size: 24px;
	line-height: 28px;
	
	@media (min-width: 768px) {
		font-size: 48px;
		line-height: 1.3;
	}
}
.about-intro {
	display: flex;
	flex-direction: column;
	padding-bottom: 120px;
	
	@media (min-width: 768px) {
		flex-direction: row;
	}
}
.about-intro__text {
	width: 100%;	
	box-sizing: border-box;
	
	@media (min-width: 768px) {
		width: 50%;
		padding-right: 100px;
		box-sizing: content-box;
	}
}
.about-intro__text .info-text {
	padding-top: 30px;
	position: relative;
}
.about-intro__text .info-text::before {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 50px;
	height: 2px;
	background: #af0827;
}
.about-intro__text .info-text p {
	margin-bottom: 30px;
	line-height: 24px;
}
.about-intro__directions {
	width: 100%;
	padding: 0 20px;
	
	@media (min-width: 768px) {
		width: 50%;
		padding: 0;
	}
}
.directions__item {
	border-top: 1px solid #999;
	position: relative;
	display: flex;
	flex-direction: column;
	padding: 20px 20px 40px 10px;
	
	@media (min-width: 768px) {
		padding: 30px 30px 30px 0;
		flex-direction: row;
	}
}
.directions__index {
	position: absolute;
	top: -12px;
	left: -17px;
	font-size: 14px;
}
.directions__title {
	width: 100%;
	font-size: 24px;
	padding-right: 0;
	margin-bottom: 10px;
	line-height: 36px;
	box-sizing: border-box;
	
	@media (min-width: 768px) {
		width: 160px;
		margin-bottom: 0;
		padding-right: 15px;
		box-sizing: content-box;
	}
}
.directions__content {
	width: 100%;
	line-height: 24px;
	box-sizing: border-box;
	
	@media (min-width: 768px) {
		width: 270px;
		box-sizing: content-box;
	}
}
.directions__content ul {
	list-style-type: disc;
}
.directions__content li {
	list-style-type: disc;
}
.about-add {
	padding-bottom: 120px;
	display: flex;
	flex-direction: column;
	
	@media (min-width: 768px) {
		flex-direction: row;
	}
}
.about-add__heading {
	width: 100%;
	padding: 0 15px 15px;
	box-sizing: border-box;
	
	@media (min-width: 768px) {
		width: 310px;
		padding: 40px 35px;
	}
	
}
.about-add__title {
	font-size: 24px;
	line-height: 32px;
	
	@media (min-width: 768px) {
		font-size: 34px;
		line-height: 51px;
	}
}
.about-add__content {
	width: 100%;
	border-top: 2px solid #af0827;
	padding: 20px 10px;
	line-height: 22px;
	box-sizing: border-box;
	
	@media (min-width: 768px) {
		width: 650px;
		border-top: 0;
		border-left: 2px solid #af0827;
		padding: 38px 60px 38px 38px;
		line-height: 24px;
		box-sizing: content-box;
	}
}
.about-add__text {
}
.about-service {
	padding-bottom: 5px;
	
	@media (min-width: 768px) {
		padding-bottom: 40px;
	}
}
.services__list {
	display: flex;
	flex-wrap: wrap;
	padding: 0 20px;
	
	@media (min-width: 768px) {
		padding: 0;
	}
}
.service__item {
	width: 100%;
	margin-bottom: 100px;
	position: relative;
	border-top: 1px solid #999;
	
	@media (min-width: 768px) {
		width: 44%;
		margin-right: 6%;
	}
}
.service__count {
	position: absolute;
	top: -80px;
	left: -30px;
	color: rgba(255, 255, 255, 0.05);
	font-size: 200px;
	line-height: 180px;
}
.service__header {
	position: relative;
	z-index: 10;
	display: block;
	padding: 20px 0;
	color: #fff;
	transition: 0.3s linear;
}
.service__header:hover {
	color: #af0827;
}
.service__content {
	line-height: 24px;
}
.service__title {
	font-size: 28px;
	line-height: 32px;
	font-weight: normal;
	
	@media (min-width: 768px) {
		font-size: 32px;
		line-height: 48px;
	}
}
.service__index {
	position: absolute;
	top: -12px;
	left: -22px;
	color: #af0827;
	font-size: 14px;
}