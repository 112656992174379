.main-header {
	position: fixed;
	top: 0;
  left: 0;
	width: 100%;
//	height: 82px;
	z-index: 100;
	transition: 0.3s linear;
	&__bg {
		height: 0;
		max-height: 0px;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background: $dark;
		z-index: 1;
		
		@media (min-width: 768px) {
			max-height: unset;
		}
	}
	&__wrap {
		position: relative;
//    padding: 10px 0 10px;
//		margin: 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
		box-sizing: content-box;
	}
	&__logo {
		position: relative;
    top: 0;
		left: 0;
		height: 56px;
		padding: 10px 15px;
		display: flex;
    justify-content: center;
    align-items: center;
    
		transition: all 0.5s linear;
		
		@media (min-width: 768px) {
			top: 20px;
			left: 30px;
			height: 76px;
			padding: 10px 30px;
		}
		
		&:hover,
		&:focus {
			opacity: 0.7;
		}
		
		img {
			&:last-child {
				display: none;
				
				
			}
		}
	}
	&.fixed {
		.main-header__logo {
			top: 0;
			left: 0;
		}
		.hamburger {
			top: 0;
    	right: 0;
		}
	}
}

.logo__wrapper {
	position: relative;
	height: 36px;
	display: flex;
	align-items: center;
	outline: none;
	z-index: 2;
	img {
		display: block;
		width: auto;
	}
	img:nth-child(1) {
		height: 36px;
		
		@media (min-width: 768px) {
			margin-right: 15px;
		}
	}
	img:nth-child(2) {
		display: none;
		
		@media (min-width: 768px) {
			display: block;
			height: 20px;
		}
		
	}
}

.main-nav {
	padding-top: 8px;
	&__list {
    display: flex;
    align-items: center;
    justify-content: center;
	}
	&__item:not(:last-child) {
		position: relative;
    margin-right: 40px;
	}
	&__item:last-child {
		.main-nav__link::after {
			display: none;
		}
	}
	&__link {
		display: block;
		position: relative;
		color: #fff;
		font-size: 12px;
		line-height: 18px;
		letter-spacing: 1.2px;
		text-transform: uppercase;
		font-family: "RalewaySemiBold", Arial, sans-serif;
		transition: all .3s linear;
		&:hover,
		&:focus {
			color: $accent;
			&::after,
			&.active::after {
				content: '';
				display: block;
				position: absolute;
				bottom: -15px;
				left: 0;
				height: 1px;
				width: 100%;
				background-color: #af0827;
				transition: all 0.3s linear;
			}
		}
	}
}

.hamburger {
	cursor: pointer;
	position: relative;
	top: 0;
	right: 0;
	display: flex;
	align-items: center;
	height: 56px;
	padding: 0;
	transition: all 0.5s linear;
	
	@media (min-width: 768px) {
		top: 20px;
		right: 30px;
		height: auto;
		padding: 10px 30px;
	}
	&__bg {
		height: 0;
		max-height: 0;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background: $dark;
		z-index: 1;
		
		@media (min-width: 768px) {
			max-height: unset;
		}
	}
	&__text {
		display: none;
		
		@media (min-width: 768px) {
			display: block;
			position: relative;
			color: #fff;
			text-transform: uppercase;
			font-size: 12px;
			z-index: 2;
		}
	}
	&:hover,
	&:focus {
		span:nth-child(1) {
			top: 15px;
		}
		span:nth-child(3) {
			top: 41px;
		}
	}
	&__wrap {
		position: relative;
		padding: 15px 0 15px 15px;
    width: 50px;
    height: 26px;
		box-sizing: content-box;
		z-index: 2;
	}
	span {
    display: block;
    width: 36px;
    height: 1px;
    background: #fff;
    transition: all 0.35s;
    position: absolute;
    right: 15px;
	}
	span:nth-child(1) {
    top: 18px;
	}
	span:nth-child(2) {
    top: 28px;
    opacity: 1;
    transition: opacity 0.35s;
		transition: background 0.35s;
	}
	span:nth-child(3) {
    top: 38px;
		width: 28px;
	}
}
.post-page .main-header__bg {
	height: 82px !important;
}