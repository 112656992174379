// Colors
$white: #ffffff;
$black: #000000;

$accent: #af0827;
$dark: #222;
$gray: #666;

$gradient: linear-gradient(to left, #222, #ae0926);
$gradient-reverse: linear-gradient(to right, #222, #ae0926);

// Sizes
$base-font-size: 16px;

$breakpoints: (
  "phone"       : 400px,
  "phone-wide"  : 480px,
  "phablet"     : 560px,
  "tablet-small": 640px,
  "tablet"      : 768px,
  "tablet-wide" : 1024px,
  "desktop"     : 1248px,
  "desktop-wide": 1440px
);

$content-size: 1200px;

// Fonts