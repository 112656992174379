.main-menu {
	position: fixed;
	z-index: 10000;
	top: 0;
	right: -370px;
	background-color: #222;
	height: 100vh;
	overflow-y: auto;
	box-shadow: 1px 0 10px 0 #fff;
	transition: 0.3s linear;
	&__wrapper {
		width: 300px;
		padding: 30px 20px 10px;
		background-color: #222;
		
		@media (min-width: 768px) {
			width: 350px;
			padding: 60px 30px 10px;
		}
	}
	&__list {
		
	}
	&__item {
		margin: 5px 0;
		
		@media (min-width: 768px) {
			margin: 10px 0;
		}
	}
	&__link {
		display: block;
		font-size: 18px;
		line-height: 18px;
		padding: 5px 20px;
		color: #fff;
		transition: 0.2s linear;
		
		@media (min-width: 768px) {
			font-size: 20px;
			line-height: 20px;
			padding: 10px 20px;
		}
		&:hover {
			color: #af0827;
		}
	}
	&__address {
		color: #fff;
		padding: 10px 20px;
	}
	.order__btn-wrap {
		margin-left: 20px;
		margin-top: 20px;
	}
}

.menu-close {
	position: absolute;
	right: 15px;
	top: 15px;
	cursor: pointer;
	
	@media (min-width: 768px) {
		right: 52px;
		top: 22px;
	}
	&__wrap {
		position: relative;
		width: 40px;
		height: 40px;
		&::before,
		&::after {
			position: absolute;
			content: "";
			display: block;
			height: 1px;
			width: 40px;
			background-color: #fff;
			top: 50%;
		}
		&::before {
			transform: rotate(-45deg);
		}
		&::after {
			transform: rotate(45deg);
		}
	}
}

