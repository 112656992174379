@import "utils/libs";
@import "utils/vars";
@import "utils/mixins";
@import "utils/fonts";
@import "utils/magnific-popup";

html {
  font-size: $base-font-size;
}
.page-container {
	position: relative;
	width: 100vw;
	height: 100%;
//	overflow-x: hidden;
}
.outer-container {
	position: absolute;
	height: 100%;
	width: 100vw;
	top: 0;
	left: 0;
	z-index: 2;
	transition: 0.3s linear;
}
body {
	height: 100%;
	background-color: $dark;
//	position: relative;
	overflow-x: hidden;
	padding-top: 100vh;
	font-size: 14px;
	line-height: 21px;
	font-family: 'RalewayRegular', Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	z-index: 1;
	&.no-scroll {
		height: 100vh;
		overflow: hidden;
	}
	&.inner-page {
		padding-top: 0;
	}
	&.menu-open {
		.cc-window {
			left: -350px;
		}
		.main-menu {
			right: 0;
		}
		.main-header {
			left: -300px;
			
			@media (min-width: 768px) {
				left: -350px;
			}
		}
		.outer-container {
			left: -300px;
			
			@media (min-width: 768px) {
				left: -350px;
			}
		}
	}
}

h1, h2, h3, h4, h5, h6 { margin: 0; }

ul, li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a { text-decoration: none; }

p { margin: 0; }

audio,
canvas,
iframe,
img,
svg,
video,
textarea { vertical-align: middle; }

textarea { resize: none; }

section { position: relative; }

input,
select,
button { outline: none; }

*, *:before, *:after { box-sizing: border-box; }

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content { flex: 1 0 auto; }

.container {
  margin: 0 auto;
  max-width: $content-size;
  width: 100%;
}

.main {
	margin-top: 150px;
	text-align: center;
	color: #fff;
	
	@media (min-width: 768px) {
		margin-top: 250px;
	}
}
.main-content {
	width: 100%;
	overflow-x: hidden;
}
.main__title {
	font-size: 24px;
	line-height: 28px;
	width: 100%;
	margin: 0 auto;
	padding: 0 15px;
	font-weight: normal;
	
	@media (min-width: 768px) {
		font-size: 40px;
		line-height: 44px;
		width: 600px;
		padding: 0;
	}
	span {
    font-size: 30px;
    line-height: 40px;
    text-transform: uppercase;
		
		@media (min-width: 768px) {
			font-size: 60px;
    	line-height: 66px;
		}
	}
}
.main__title::after {
	content: '';
	display: block;
	position: relative;
	width: 1px;
	height: 80px;
	background: #fff;
	left: 50%;
	top: 80px;
	margin-bottom: 120px;
}
.main__subtitle {
	font-size: 18px;
	width: 100%;
	margin: 0 auto;
	padding: 0 15px;
	
	@media (min-width: 768px) {
		width: 700px;
		padding: 0;
	}
}
.main__desc-italic {
	font-style: italic;
	font-size: 16px;
	width: 100%;
	margin: 30px auto 0;
	padding: 0 15px;
	
	@media (min-width: 768px) {
		width: 700px;
		padding: 0;
	}
}

//Модули
@import "modules/modules";
@import "sections/sections";
