@import 'menu';
@import 'main-slider';
@import 'main-productions';
@import 'productions';
@import 'main-blog';
@import 'main-cooperation';
@import 'cooperation';
@import 'main-products';
@import 'product';
@import 'about';
@import 'contacts';
@import 'blog';
@import 'post';
@import 'modal';

.section-header {
	text-align: center;
	margin: 0 auto 40px;
	&__title {
		font-size: 20px;
    line-height: 22px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-family: "RalewayRegular", sans-serif;
    margin-bottom: 15px;
    position: relative;
    display: inline-block;
		overflow: hidden;
		span {
			display: block;
			@media (min-width: 768px) {
				opacity: 0;
				animation: title-anim-out .1s forwards linear;
			}
			&::after {
				content: '';
				display: block;
				width: 70px;
				height: 2px;
				background-color: $accent;
				margin: 12px auto 0;
			}
			&::before {
				content: '';
				display: block;
			}
		}
	}
	&__title-bg {
		@media (min-width: 768px) {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: $dark;
			z-index: 3;
			animation: title-bg-anim-out .1s forwards linear;
		}
	}
	&__subtitle-wrap {
		max-width: 540px;
		margin: 0 auto;
		padding: 0 15px;
		overflow: hidden;
		font-size: 16px;
		line-height: 1.33;
		
		@media (min-width: 768px) {
			padding: 0;
		}
	}
	&__subtitle {
		@media (min-width: 768px) {
			transform: translateX(-100%);
			transition: all .2s linear;
		}
	}
	&.animated {
		.section-header__title {
			span {
				@media (min-width: 768px) {
					opacity: 1;
					animation: title-anim-in .8s forwards linear;
				}
			}
		}
		.section-header__title-bg {
			@media (min-width: 768px) {
				animation: title-bg-anim-in .8s forwards linear;
			}
		}
		.section-header__subtitle {
			@media (min-width: 768px) {
				transform: translateX(0);
				transition: all .5s linear;
			}
		}
	}
}
@keyframes title-anim-in {
	from {
		transform: translateX(-100%);
	}
	50% {
		transform: translateX(0);
	}
	to {
		transform: translateX(0);
	}
}
@keyframes title-anim-out {
	from {
		transform: translateX(0);
		opacity: 1;
	}
	50% {
		transform: translateX(0);
		opacity: 1;
	}
	to {
		transform: translateX(-100%);
	}
}
@keyframes title-bg-anim-in {
	from {
		transform: translateX(-101%);
	}
	50% {
		transform: translateX(0%);
	}
	to {
		transform: translateX(101%);
	}
}
@keyframes title-bg-anim-out {
	from {
		transform: translateX(101%);
	}
	50% {
		transform: translateX(0%);
	}
	to {
		transform: translateX(-101%);
	}
}
.main-blog .section-header__title-bg,
.main-productions .section-header__title-bg {
	background-color: $accent;
}
.headline__title::after {
		content: '';
    display: block;
    width: 70px;
    height: 2px;
    background-color: #af0827;
    margin: 15px auto 0;
	}