.main-blog {
	background-color: $dark;
	color: #fff;
	padding-top: 100px;
	padding-bottom: 40px;
	
	@media (min-width: 768px) {
		padding-top: 0;
	}
	&::before {
		@media (min-width: 768px) {
			content: '';
			display: block;
			height: 1px;
			width: 100%;
			position: absolute;
			bottom: calc(100% - 3px);
			left: 0;
			z-index: 11;
			background: linear-gradient(to top, rgba($dark, 1) 50%, rgba($dark, .6) 80%, rgba($dark, .3) 90%, rgba($dark, 0) 100%);
			transition: .8s linear;
		}
	}
	&.animated {
		&::before {
			@media (min-width: 768px) {
				opacity: 1;
				height: 130vh;
				transition: .8s linear;
			}
		}
	}
	.section-header__title-bg {
		background: $accent;
	}
	&__wrap {
		width: 100%;
		padding: 0 15px;
		
		@media (min-width: 768px) {
			width: 1200px;
			padding: 0;
		}
	}
	&__list {
		margin-bottom: 56px;
		
		@media (min-width: 768px) {
			display: flex;
			justify-content: space-between;
		}
		//&.animated {
		//	.main-blog__item {
		//		transform: translateY(0);
		//	}
		//	.main-blog__item:nth-child(1) {
		//		transition: 0.5s linear;
		//	}
		//	.main-blog__item:nth-child(2) {
		//		transition: 1.0s linear;
		//	}
		//	.main-blog__item:nth-child(3) {
		//		transition: 1.5s linear;
		//	}
		//}
	}
	&__item {
		position: relative;
		transition: 0.2s linear;
		width: 100%;
		margin-bottom: 50px;
		display: block;
		color: #fff;
		
		@media (min-width: 576px) {
			width: 330px;
			margin: 0;
			//transform: translateY(300px);
		}
		&:hover,
		&:focus {
			.main-blog__preview {
				padding: 6px;
			}
			.main-blog__img {
				width: 120%;
				height: 120%;
				margin-left: 0;
				margin-top: -10%;
			}
			.main-blog__hover {
				&::after,
				span {
					opacity: 1;
				}
			}
			.main-blog__date {
				span::before {
					transform: translate3d(0, 0, 0);
				}
			}
			.main-blog__title {
				color: $accent;
			}
		}
	}
	&__preview {
		position: relative;
		width: 100%;
		height: 300px;
		padding: 0;
		margin-bottom: 30px;
		background: $gradient;
		transition: all 0.3s linear;
	}
	&__hover {
		position: relative;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
		&::after {
			position: absolute;
			width: 100%;
			height: 100%;
			content: '';
			display: block;
			background: rgba(0,0,0,.4);
			z-index: 1;
			opacity: 0;
			transition: opacity 0.3s linear;
		}
		span {
			position: absolute;
			display: flex;
			align-items: center;
			transform: rotate(-90deg);
			font-family: RalewaySemiBold, sans-serif;
			font-size: 12px;
			line-height: 18px;
			letter-spacing: 3px;
			text-transform: uppercase;
			z-index: 2;
			opacity: 0;
			transition: opacity 0.25s linear;
			&::before,
			&::after {
				content: '';
				display: block;
				width: 40px;
				height: 1px;
				background: #fff;
			}
			&::before {
				margin-right: 10px;
			}
			&::after {
				margin-left: 10px;
			}
		}
	}
	&__img {
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		transition: all 0.3s linear;
	}
	&__date {
    position: absolute;
    bottom: -18px;
    left: -15px;
		z-index: 5;
		span {
			display: block;
			font-size: 14px;
			width: 210px;
			line-height: 44px;
			text-align: center;
			position: relative;
			overflow: hidden;
			z-index: 2;
			&::before,
			&::after {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
			&::before {
				background: $accent;
				z-index: -1;
				transform: translate3d(-100%, 0, 0);
				transition: transform .42s cubic-bezier(.645, .045, .355, 1);
			}
			&::after {
				background: #222;
				z-index: -2;
			}
		}
		
	}
	&__content {
		
	}
	&__title {
		font-family: "RalewaySemiBold", Arial, sans-serif;
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
    margin-bottom: 16px;
		transition: color 0.3s linear;
	}
	&__text {
		
	}
	&__btn-wrap {
    
	}
	&__btn {
		
	}
}
