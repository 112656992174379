.map-container  {
	position: relative;
	margin: 30px 0 100px;
  
	@media (min-width: 768px) {
		height: 500px;
	}
}
.map-container .btn-block {
	margin-bottom: 20px;
}

.map-container__title {
	padding-top: 120px;
	width: 300px;
	text-align: center;
	margin: 0 auto;
	color: #fff;
	font-size: 20px;
	text-transform: uppercase;
	
	@media (min-width: 768px) {
		text-align: left;
		width: 950px;
	}
}
.map-bg {
	background: #666666;
	
	@media (min-width: 768px) {
		position: absolute;
		left: 0;
		top: 80px;
		height: 380px;
		right: 50px;
	}
}
.map-bg-txt {
	height: 100%;
	padding: 30px 28px;
	width: 230px;
	box-sizing: border-box;
	@media (min-width: 768px) {
		position: absolute;
		top: 0;
		right: 0;
	}
}
.map-bg-txt svg {
	width: 17px;
  height: 25px;
  fill: #fff;
}
.map-txt-title {
	padding: 20px 0;
  font-weight: 700;
  font-size: 16px;
  color: #0d0d0d;
  line-height: 20px;
}
.map-wrap {
	height: 400px;
	box-shadow: 0 0 40px rgba(0, 0, 0, .12);
	width: 100%;
	
	@media (min-width: 768px) {
		position: absolute;
		top: 0;
		height: 100%;
		box-shadow: 0 0 40px rgba(0, 0, 0, .12);
		width: 880px;
		right: 280px;
	}
}
#map.map {
	height: 100%;
	width: 100%;
}
#map-2.map {
	height: 100%;
	width: 100%;
}

.contact-info {
	
}
.contact-info__wrapper {
	width: 300px;
	margin: 130px auto 0;
	color: #fff;

	@media (min-width: 768px) {
		width: 1000px;
		height: 300px;
		position: relative;
	}
	> * {
		@media (max-width: 767px) {
			margin-bottom: 20px;
			position: relative;
			margin-left: 35px;
		}
	}
}
.contact-info__email {
	@media (min-width: 768px) {
		top: 0;
		left: 60px;
		position: absolute;
	}
}
.contact-info__answer {
	@media (min-width: 768px) {
		position: absolute;
	}
}
.contact-info__title {
	font-size: 12px;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: 2px;
	position: relative;
	margin-bottom: 4px;
}
.contact-info__title::before {
	content: '';
	display: block;
	height: 1px;
	width: 20px;
	background: #af0827;
	position: absolute;
	left: -25px;
	top: 50%;
	
	@media (min-width: 768px) {
		left: -45px;
		width: 30px;
	}
}
.contact-info__text {
	font-size: 16px;
	line-height: 20px;
	color: #fff;
}
.contact-info__text span {
	text-transform: uppercase;
	font-weight: 700;
}
.contact-info__phone {
	@media (min-width: 768px) {
	  top: 98px;
    left: 170px;
		position: absolute;
	}
}
.contact-info__address {
	@media (min-width: 768px) {
		top: 0;
		left: 560px;
		position: absolute;
	}
	&--rpoductions {
		@media (min-width: 768px) {
			top: 190px;
			left: 670px;
		}
	}
}
.contact-info__work-time {
	@media (min-width: 768px) {
		top: 190px;
		left: 220px;
		position: absolute;
	}
}
.map-container .btn {
	border: 1px solid #fff;
	transition: 0.2s linear;
	&::before {
		z-index: 1;
	}
	span {
		position: relative;
		z-index: 2;
	}
	&:hover {
		border-color: transparent;
	}
}