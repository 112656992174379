.cooperation {
	color: #fff;
	margin-top: 120px;
}
.cooperation__wrapper {
	width: 300px;
	margin: 0 auto;
	padding-bottom: 120px;
	
	@media (min-width: 768px) {
		width: 1100px;
	}
}
.cooperation__desc {
	padding-bottom: 80px;
	text-align: center;
	font-size: 18px;
	line-height: 24px;
	
	@media (min-width: 768px) {
		font-size: 24px;
		line-height: 36px;
	}
}

.cooperation__cols {
	display: flex;
	flex-direction: column;
	padding-bottom: 120px;
	
	@media (min-width: 768px) {
		flex-direction: row;
		justify-content: space-around;
	}
}
.cooperation__text {
	width: 100%;
	padding: 0;
	font-size: 16px;
	line-height: 24px;
	box-sizing: border-box;
	
	@media (min-width: 768px) {
		width: 40%;
		padding: 0 40px;
		box-sizing: content-box;
	}
	&:first-child {
		margin-bottom: 30px;
		@media (min-width: 768px) {
			margin-bottom: 0;
		}
	}
}
.cooperation__list {
}
.cooperation__item {
	display: flex;
	flex-direction: column;
	padding: 25px 0;
	
	@media (min-width: 768px) {
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}
}
.cooperation__item:not(:last-child) {
	border-bottom: 1px solid #999;
}
.cooperation .item__number {
	font-size: 18px;
	line-height: 27px;
	padding-left: 60px;
	position: relative;
}
.cooperation .item__number::before {
	content: '';
	display: block;
	position: absolute;
	top: 50%;
	left: 0;
	width: 40px;
	height: 1px;
	background: #af0827;
}
.cooperation .item__text {
	font-size: 22px;
	line-height: 28px;
	margin-top: 15px;
	
	@media (min-width: 768px) {
		margin: 0;
		font-size: 32px;
		line-height: 48px;
	}
}