.btn {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 44px;
  line-height: 44px;
	color: #fff;
	text-decoration: none;
	text-transform: uppercase;
	background: transparent;
	font-weight: 800;
	font-size: 12px;
	letter-spacing: 1.2px;
	cursor: pointer;
	overflow: hidden;
	box-shadow: 0 1px 3px 0 rgba(255, 255, 255, 0.35), 0 3px 15px 0 rgba(255, 255, 255, 0.35);
	transition: color 0.35s;
	&::before,
	&::after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		display: block;
		width: 100%;
		height: 100%;
	}
	&::before {
		background: $gradient;
		z-index: -1;
		transform: translateX(-100%);
		transition: transform .42s cubic-bezier(.645, .045, .355, 1);
	}
	&::after {
		left: 0;
		border: 1px solid rgba(255,255,255,1);
		z-index: -2;
	}
	&:hover,
	&:focus {
		&::before {
			transform: translateX(0);
			transition: transform .42s cubic-bezier(.645, .045, .355, 1);
		}
	}
	&__wrap {
		width: 240px;
		position: relative;
		z-index: 1;
		
		@media (min-width: 768px) {
			width: 240px;
		}
	}
}