.product__wrapper {
	width: 100%;
	margin: 100px auto 0;
	padding: 0 15px;
	
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	
	@media (min-width: 768px) {
		width: 1200px;
		margin: 100px auto 0;
		padding: 60px 0 0;
	}
}
.product__wrapper .product__item {
	width: 100%;
	margin-bottom: 170px;
	
	@media (min-width: 768px) {
		width: 500px;
		margin-right: 50px;
		margin-bottom: 170px;
	}
}
.product__wrapper .product__item:nth-child(2n) {
	margin-right: 0;
}

.accommodation__slide {
	width: auto;
	height: 260px;
	position: relative;
	-webkit-box-shadow: 0 10px 20px 0 rgba(159, 164, 189, .5);
	box-shadow: 0 10px 20px 0 rgba(159, 164, 189, .5);
	
	@media (min-width: 768px) {
		height: 360px;
	}
}
.accommodation__link {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}
.accommodation__image-wrap {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
  height: 100%;
	overflow: hidden;
}
.accommodation__image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    transition: transform .7s;
    -ms-transform: scale(1);
    transform: scale(1);
    width: 100%;
    height: 100%;
    
}
.accommodation__image_overlay {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
}
.accommodation__slide:hover .accommodation__image {
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}
.accommodation__border {
    top: 16px;
    bottom: 16px;
    left: 16px;
    right: 16px;
}
.border {
	position: absolute;
	left: 15px;
	top: 15px;
	right: 15px;
	bottom: -47px;
	border: 1px solid #000;
	pointer-events: none;
	transition: all 0.35s;
}
.accommodation__texts {
	position: absolute;
	left: 30px;
	top: 293px;
	width: 80%;
	background: #fff;
	padding: 0 10px;
	
	@media (min-width: 768px) {
		top: 393px;
		width: 400px;
	}
}
.accommodation__number {
    display: block;
    color: #c8c3d0;
    font-size: 12px;
    line-height: 1.33333;
    letter-spacing: .86px;
    padding-left: 3px;
    padding-bottom: 5px;
}
.accommodation__caption, .accommodation__description {
    position: relative;
    margin-bottom: 30px;
}
.accommodation__caption {
	font-size: 11px;
	text-transform: uppercase;
	letter-spacing: 1.2px;
	font-weight: 700;
	display: block;
	line-height: 22px;
	color: rgb(45, 45, 52);
	width: 100%;
	margin-bottom: 0;
	transition: color 0.35s;
	
	@media (min-width: 768px) {
		font-size: 12px;
		line-height: 26px;
	}
}
.accommodation__caption {
    
}
.inner-page .accommodation__caption {
	  color: #fff;
}
.accommodation__slide:hover .border {
	 border-color: #af0827;
}
.accommodation__slide:hover .accommodation__caption {
	 color: #af0827;
}
.inner-page .product {
	background-color: #222;
	transition: background-color 1.5s ease;
}
.inner-page .product .border,
.inner-page .product .border {
	border-color: #fff;
}
.inner-page .product .accommodation__texts {
	transition: background-color 1.5s ease;
	background-color: rgb(34,34,34);
	color: #fff;
}
.inner-page .product.color-color1,
.inner-page .product.color-color1 .accommodation__texts {
	background-color: rgb(34,34,34);
	color: #fff;
}
.inner-page .product.color-color3 .accommodation__caption,
.inner-page .product.color-color4 .accommodation__caption {
	color: #000;
}
.inner-page .product.color-color2,
.inner-page .product.color-color2 .accommodation__texts {
	background-color: rgb(106,106,106);
	color: #fff;
}
.inner-page .product.color-color3,
.inner-page .product.color-color3 .accommodation__texts {
	background-color: rgb(180,180,180);
}
.inner-page .product.color-color4,
.inner-page .product.color-color4 .accommodation__texts {
	background-color: rgb(255,255,255);
}
.inner-page .product.color-color1 .border,
.inner-page .product.color-color2 .border {
	border-color: #fff;
}
.inner-page .product.color-color3 .border,
.inner-page .product.color-color4 .border {
	border-color: #222;
}
.inner-page .product.color-color1 .accommodation__slide:hover .border,
.inner-page .product.color-color2 .accommodation__slide:hover .border,
.inner-page .product.color-color3 .accommodation__slide:hover .border,
.inner-page .product.color-color4 .accommodation__slide:hover .border {
	border-color: #af0827;
}
.inner-page .product.color-color1 .accommodation__slide:hover .accommodation__caption,
.inner-page .product.color-color2 .accommodation__slide:hover .accommodation__caption,
.inner-page .product.color-color3 .accommodation__slide:hover .accommodation__caption,
.inner-page .product.color-color4 .accommodation__slide:hover .accommodation__caption {
	color: #af0827;
}
.products-template {
	display: block;
	width: 100%;
	height: 100vh;
}
.button-load {
	width: 240px;
	margin: 0 auto;
	position: relative;
	z-index: 5;
}
.button-load button {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 44px;
	width: 100%;
	line-height: 44px;
	color: #fff;
	padding: 0;
	text-decoration: none;
	text-transform: uppercase;
	background-color: transparent;
	font-weight: 800;
	font-size: 12px;
	letter-spacing: 1.2px;
	border: none;
	cursor: pointer;
	overflow: hidden;
	box-shadow: 0 1px 3px 0 rgba(255, 255, 255, 0.35), 0 3px 15px 0 rgba(255, 255, 255, 0.35);
	transition: color 0.35s;
	&::before,
	&::after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		display: block;
		width: 100%;
		height: 100%;
	}
	&::before {
		background: $gradient;
		z-index: -1;
		transform: translateX(-100%);
		transition: transform .42s cubic-bezier(.645, .045, .355, 1);
	}
	&::after {
		left: 0;
		border: 1px solid rgba(255,255,255,1);
		z-index: -2;
	}
	&:hover,
	&:focus {
		&::before {
			transform: translateX(0);
			transition: transform .42s cubic-bezier(.645, .045, .355, 1);
		}
	}
	&:active {
		&::before {
			transform: translateX(-100%);
			transition: transform .42s cubic-bezier(.645, .045, .355, 1);
		}
	}
}

.products-page {
	padding-bottom: 60px;
}